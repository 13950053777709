import React, { useState, useContext, useEffect } from "react"

import AuthContext from '../context/AuthProvider';
import { getStorage, setStorage, getQuerystringValue, handleLink } from '../helpers/general'

import Layout from "../components/organisms/Layout/Layout"
import Seo from "../components/organisms/Seo/Seo"
import Container from "../components/atoms/Container/Container"
import PageTitle from "../components/atoms/PageTitle/PageTitle"
import Button from "../components/atoms/Button/Button"
import Checkbox from "../components/atoms/Checkbox/Checkbox"
import Dialog from '../components/atoms/Dialog/Dialog';
import FormInputField from '../components/atoms/FormInputField/FormInputField';
import NewCustomer from '../components/molecules/NewCustomer/NewCustomer';
import * as styles from './login.module.css'
import ContentfulContext from "../context/ContentfulProvider";
import { get } from "lodash";
import ContentfulTranslationText from "../components/atoms/ContentfulTranslationText/ContentfulTranslationText";


const LoginPage = () => {
    const contentful = useContext(ContentfulContext);
    const translations = get(contentful, 'translationData', {});

    const auth = useContext(AuthContext);
    const handleLogin = auth && auth.login;
    const isLoggedIn = auth && auth.state.isLoggedIn;
    const userChecked = auth && auth.state.userChecked;
    let redirectTo = handleLink('/account/', process.env.GATSBY_ENVIRONMENT_PATH);
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    const afterAuthCalls = getStorage('_afterAuth');
    const [remember, setRemember] = useState(true);
    const [dialogMessage, setDialogMessage] = useState(false);
    const [isAttempting, setAttempting] = useState(false);

    const [values, setValues] = useState({
        email: '',
        password: '',
      });
      

    let afterAuthCallsObject = {};
    if (afterAuthCalls) {
        afterAuthCallsObject = JSON.parse(afterAuthCalls);
    }

    const onChangeCheckbox = (e) => {
        return setRemember(e.target.checked)
    }

    if (typeof window !== 'undefined' && getQuerystringValue('redirectTo')) {
        redirectTo = getQuerystringValue('redirectTo');
        setStorage('forcedPage', `${window.location.origin}${redirectTo}`, true);
    }

    useEffect(() => {
        if (isLoggedIn && typeof window !== 'undefined') {
            window.location = handleLink('/account/', process.env.GATSBY_ENVIRONMENT_PATH);
        }
    }, [isLoggedIn])

    const attemptLogin = (e) => {
        e.preventDefault();
        setAttempting(true);
        handleLogin(values.email, values.password).then(() => {
            // success; automatically redirects to home;
        }).catch((err) => {
            setDialogMessage(err);
            setAttempting(false);
        })
    }

    const clearDialog = () => {
        setDialogMessage(false);
    }

    const onHandleChange = (id, value) => {
        let tempValues = Object.assign({}, values);
        tempValues[id] = value;
        setValues(tempValues);
      };


    if (!isLoggedIn && userChecked) {
        return (
        <Layout>
            <Seo title="Login" />
            <Container size="large">
                <div className={`${styles.gridMargin}`}>
                    <div className={`${styles.loginForm}`}>
                        <div>
                            <PageTitle title={get(translations, 'login.title', 'Login')} />
                        </div>
                        {'action' in afterAuthCallsObject && afterAuthCallsObject.action === 'saveWishlist' && (
                            <div className={styles.notice}>
                                <ContentfulTranslationText keyName="accountSaveWishlistNotice">
                                    An account is required to save a wishlist. Please login or sign up for a free account to save your wishlist.
                                </ContentfulTranslationText>
                            </div>
                        )}
                        <form onSubmit={(e) => attemptLogin(e)}>
                            <FormInputField id="email" type="input" placeholder={get(translations, 'emailAddress.title', 'Email Address')} value={values['email']} handleChange={onHandleChange}/>
                            <FormInputField id="password" type="password" placeholder="Password" value={values['password']} handleChange={onHandleChange}/>
                            {/* <div className="formField">
                                <label htmlFor="email">Email Address</label>
                                <input type="email" name="email" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="formField">
                                <label htmlFor="password">Password</label>
                                <input type="password" name="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                            </div> */}
                            <div className={`${styles.formRemember}`}>
                                <Checkbox
                                name="rememberme"
                                label={get(translations, 'rememberMe.title', 'Remember me')}
                                action={(e) => onChangeCheckbox(e)}
                                isChecked={remember}
                                />
                                <a href="https://user.forcite.io/v1/user/forgotpassword" target="_blank" rel="noopener noreferrer">
                                <ContentfulTranslationText keyName="forgotYourPassword">Forgot your password?</ContentfulTranslationText>
                                </a>
                            </div>
                            <div className={`${styles.formActions}`}>
                                <Button fullWidth level="primary" type="buttonSubmit" disabled={isAttempting}>{isAttempting ? `${get(translations, 'pleaseWait.title', 'Please wait')}...` : get(translations, 'login.title', 'Login')}</Button>
                                <Dialog open={dialogMessage ? true : false} 
                                    title={get(translations, 'loginUnsuccessful.title', 'Login unsuccessful')} 
                                    size="sm" 
                                    hideBtnCancel 
                                    disableBackdropClick
                                    onCancel={clearDialog} 
                                    onOk={clearDialog}>
                                    {dialogMessage}
                                </Dialog>
                                {/* <Link to="/forgot-password/">Forgot your password?</Link> */}
                            </div>
                        </form>
                    </div>
                    <NewCustomer />
                </div>
            </Container>
        </Layout>
        )
    } else {
        return null;
    }
  }
  
  export default LoginPage